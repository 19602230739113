export const SHIPPING_TIME_CODE = 'shipping_time';
export const PRICE_RANGE = 'price_range';
export const SHOW_PRICE = 'show_price';
export const ECOMMERCE_SHOW_ADDTOCARTBUTTON = 'ecommerce_show_addtocartbutton';
export const SHOW_VSC = 'show_vsc_button';
export const ECOMMERCE_SHOW_REQUESTCARTBUTTON =
  'ecommerce_show_requestcartbutton';
export const STATE_IN_STOCK = 'IN_STOCK';
export const INTERFACE = 'interface';
export const CAMERA_SERIES_CODE = 'camera_series';
export const CAMERA_CATEGORY = 'camera_category';
export const FAMILY = 'camera_product_family';
export const ACCESSORY_CATEGORY = 'accessory_category';
export const ACCESSORY_TYPE = 'accessory_type';
export const HARDWARE_FEATURES_DATA = 'hardware_features_data';
export const DISTRIBUTORS_DATA = 'distributors_data';
export const DISTRIBUTORS = 'distributors';
export const IS_NEW = 'is_new';
export const IS_COMING_SOON = 'is_coming_soon';
export const COMING_SOON_LABEL = 'coming_soon_label';
export const TECHNICAL_DRAWING = 'technical_drawing';
export const SEO_HEADLINE = 'seo_headline';
export const TEASER_TEXT = 'teaser_text';
export const BRAND = 'brand';
export const BRAND_DATA = 'brand_data';
export const CONFORMITY_ENTITY_DATA = 'conformity_entity_data';
export const MODEL_NAME = 'model_name';
export const PRODUCT_IMAGE = 'product_image';
export const DOCUMENTATION_AVAILABLE = 'documentation_available';
export const DOCUMENTATION_URL = 'documentation_url';
export const DOCUMENTATION_URL_SLUG = 'documentation_url_slug';
export const CATEGORY_ID = 'category_id';
export const VARIANT_BASE_TEXT = 'variant_base_text';
export const VARIANT_BASE_META_TITLE = 'variant_base_meta_title';
export const VARIANT_BASE_META_DESCRIPTION = 'variant_base_meta_description';
export const TESTCAMERA_AVAILABLE = 'testcamera_available';
export const FASTDELIVERY_AVAILABLE = 'basler_fastdelivery_available';
export const CAMERA_PRODUCT_LINE = 'camera_product_line';
export const SEO_INDEX = 'index';
export const SEO_FOLLOW = 'follow';
export const LAST_TIME_BUY = 'last_time_buy_date';
export const LAST_TIME_SERVICE = 'last_time_service_date';
export const DISCONTINUATION_STATUS = 'discontinuation_status';
export const DISCONTINUATION_STATUS_DISCONTINUED = 'Discontinued';
export const DISCONTINUATION_ADDITIONAL_LINKS_DATA =
  'discontinuation_additional_links_data';
export const IS_IN_BUNDLE = 'is_in_bundle';
export const PDP_ACTION_BOX_LINKS_DATA = 'pdp_action_box_links_data';
