import type { MediaItemInterface } from '@hypercodestudio/basler-components/dist/components/elements/media/Media.vue';
import { calculateImageArea } from './calculateImageArea';
import { objectEntries } from './objectEntries';
import { isDefined } from './guards/isDefined';
import type { ImageFocalPointValue } from '~/types/ImageFocalPoint';

type AspectRatio = [x: number, y: number];
type FitOptions = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';

const fallbackWidth = 800;
const DEFAULT_WIDTHS = [400, 800, 1400, 2800];
const defaultQuality = 80;
const defaultAspectRatio: AspectRatio = [16, 9];
const defaultFit: FitOptions = 'pad';

export type GenerateImageOptions = {
  keepAspectRatio?: boolean;
  originalWidth?: number;
  originalHeight?: number;
  fit?: FitOptions;
  bgColor?: string;
  widths?: ReadonlyArray<number>;
  sizes?: string;
};

export function generateImageObject(
  image?: GenerateImageObjectIImage | string,
  options: GenerateImageOptions = {
    fit: defaultFit
  }
): MediaItemInterface | undefined {
  if (image === undefined) {
    return;
  }

  const isImageEntry = typeof image !== 'string';

  const imageBaseUrl = isImageEntry
    ? image.fields?.image?.fields?.file?.url
    : image;
  if (!imageBaseUrl) {
    return;
  }

  const hasWidths = typeof options?.widths === 'object';
  const ImageWidthObject = hasWidths ? options.widths : [];

  const fallbackImageWidth = ImageWidthObject
    ? getHighestImageWidth(ImageWidthObject)
    : fallbackWidth;

  const imageWidth = isImageEntry
    ? image.fields.image.fields.file.details.image?.width
    : options?.originalWidth;
  const imageHeight = isImageEntry
    ? image.fields.image.fields.file.details.image?.height
    : options?.originalHeight;

  const filteredWidths =
    imageWidth &&
    (options?.widths ?? DEFAULT_WIDTHS).filter((width) => width <= imageWidth);

  const imageArea = isImageEntry ? calculateImageArea(image) : 'center';

  let media: MediaItemInterface = {
    content: {
      src: buildImageSrc({
        baseUrl: imageBaseUrl,
        format: 'webp',
        imageArea,
        width: fallbackImageWidth,
        quality: defaultQuality,
        aspectRatio:
          options?.keepAspectRatio === true ? undefined : defaultAspectRatio,
        fit: options.fit ?? defaultFit,
        bgColor: options.bgColor
      }),
      ...(filteredWidths && {
        srcset: filteredWidths
          .map(
            (width) =>
              buildImageSrc({
                baseUrl: imageBaseUrl,
                format: 'webp',
                imageArea,
                width,
                quality: defaultQuality,
                aspectRatio:
                  options?.keepAspectRatio === true
                    ? undefined
                    : defaultAspectRatio,
                fit: options.fit ?? defaultFit,
                bgColor: options.bgColor
              }) + ` ${width}w`
          )
          .join(', ')
      }),
      alt: isImageEntry ? image.fields?.altTag : '',
      width: imageWidth,
      height: imageHeight
    },
    caption: isImageEntry ? image.fields?.caption : '',
    styleType: isImageEntry ? image.fields?.style : 'default',
    type: 'image'
  };

  if (options.sizes) {
    media = {
      ...media,
      content: {
        ...media.content,
        sizes: options.sizes
      }
    };
  }

  return media;
}

type ImageSrcOptions = {
  baseUrl: string;
  format?: 'webp' | 'avif';
  imageArea?: ImageFocalPointValue;
  width: number;
  quality?: number;
  aspectRatio?: AspectRatio;
  fit?: FitOptions;
  bgColor?: string;
};

const imageParameterMap: Record<
  Exclude<keyof ImageSrcOptions, 'baseUrl' | 'aspectRatio'> | 'height',
  string
> = {
  format: 'fm',
  imageArea: 'f',
  width: 'w',
  height: 'h',
  quality: 'q',
  fit: 'fit',
  bgColor: 'bg'
};

export function buildImageSrcWithDefaults({
  baseUrl,
  format = 'webp',
  imageArea = 'center',
  width,
  quality = defaultQuality,
  aspectRatio = [16, 9],
  fit = defaultFit,
  bgColor
}: ImageSrcOptions) {
  return buildImageSrc({
    baseUrl,
    format,
    imageArea,
    width,
    quality,
    aspectRatio,
    fit,
    bgColor
  });
}

function buildImageSrc({
  baseUrl,
  format,
  imageArea,
  width,
  quality,
  aspectRatio,
  fit,
  bgColor
}: ImageSrcOptions) {
  const height =
    aspectRatio && getHeightFromWidthAndAspectRatio(width, aspectRatio);

  const options = {
    format,
    imageArea,
    width,
    height,
    quality,
    fit,
    bgColor
  };

  const query = objectEntries(options)
    .filter(([, value]) => isDefined(value))
    .map(([key, value]) => `${imageParameterMap[key]}=${value}`)
    .join('&');

  return `${baseUrl}?${query}`;
}

export function getHeightFromWidthAndAspectRatio(
  width: number,
  aspectRatio: AspectRatio
) {
  return Math.round(width * (aspectRatio[1] / aspectRatio[0]));
}

export function getHighestImageWidth(widths: ReadonlyArray<number>) {
  if (widths.length == 0) {
    return fallbackWidth;
  }
  return widths.reduce((a, b) => (Number(a) > Number(b) ? a : b));
}

// XXX: unify with CalculateImageAreaIImage?
export type GenerateImageObjectIImage = {
  fields: {
    image: {
      fields: {
        file: {
          url: string;
          details: {
            image?: {
              width: number;
              height: number;
            };
          };
        };
      };
    };
    focalPoint?: Record<string, any> | undefined;
    altTag?: string;
    style?: 'default' | 'gradient-border' | undefined;
    caption?: string;
  };
};
